import React, { useEffect, useState } from "react";
import NftLoot from "../../abis/KeysToTheMetaverse.json";
import Web3 from "web3";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { settingsUpdateStart } from "../store/actions/AdminAction";
import configuration from "react-global-configuration";

const DeployContractIndex = (props) => {
  useEffect(() => { }, []);

  const [nftLoot, setNftLoot] = useState(null);
  const [connectMetaMask, setConnectMetaMask] = useState(false);
  const [account, setAccount] = useState("");
  const [ethBalance, setEthBalance] = useState("");
  const [currentLimit, setCurrentLimit] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [inputData, setInputData] = useState({
    name: "",
    symbol: "",
  });
  const [buttonContent, setButtonContent] = useState("");

  const connectingMetaMask = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      console.log("Etherum enabled");
      setConnectMetaMask(true);
      getWalletAddress();
      return true;
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
      setConnectMetaMask(true);
      getWalletAddress();
      return true;
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
      return false;
    }
  };

  const getWalletAddress = async () => {
    const web3 = window.web3;
    try {
      // Network ID
      const networkId = await web3.eth.net.getId();
      const networkData = NftLoot.networks[networkId];
      if (networkData) {
        const nftLoot = new web3.eth.Contract(
          NftLoot.abi,
          configuration.get("configData.contract_address")
        );
        setNftLoot(nftLoot);
        const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]);
        const totalSupply = await nftLoot.methods.totalSupply().call();
        setTotalSupply(totalSupply);
        const ethBalance = await web3.eth.getBalance(accounts[0]);
        console.log("balance", ethBalance);
        setEthBalance(ethBalance);
        setConnectMetaMask(true);
      } else {
        window.alert("Contract not deployed to detected network.");
      }
    } catch (error) {
      console.log("errr", error);
      window.alert("Error occuried, Refresh the page");
    }
  };

  const deployContract = async () => {
    setButtonContent("Deploying contract...");
    const web3 = window.web3;
    const ecr721Token = new web3.eth.Contract(NftLoot.abi);
    try {
      const res = await ecr721Token
        .deploy({
          data: NftLoot.bytecode,
          arguments: [inputData.name, inputData.symbol],
        })
        .send(
          {
            from: account,
          },
          function (error, transactionHash) {
            // API call....
            console.log("Txt", transactionHash);
          }
        )
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("con", confirmationNumber);
        })
        .then(async (newContractInstance) => {
          console.log("New token created.", newContractInstance.address);
          console.log(
            "name",
            await newContractInstance.methods.name.call().toString()
          );
          const notificationMessage = getSuccessNotificationMessage(
            "Contract deployed. Please check the metamask.."
          );
          props.dispatch(createNotification(notificationMessage));
          console.log(newContractInstance.options.address); // instance with the new contract address
          // saving contract address
          props.dispatch(
            settingsUpdateStart({
              contract_address: newContractInstance.options.address,
            })
          );
          setButtonContent("");
          // Save the token contract address.
        });
    } catch (error) {
      console.log("errr", error);
      const notificationMessage = getErrorNotificationMessage(
        "Failed. Please try again..."
      );
      props.dispatch(createNotification(notificationMessage));
      setButtonContent("");
    }
  };

  const handleSubmit = (event) => {
    setButtonContent("Loading...");
    event.preventDefault();
    // validation check.
    if (connectMetaMask) {
      deployContract();
    } else {
      const notificationMessage = getErrorNotificationMessage(
        "Please connect wallet"
      );
      props.dispatch(createNotification(notificationMessage));
      setButtonContent("");
    }
  };

  return (
    <>
      <main class="h-full overflow-y-auto content-wrapper">
        <div class="container mx-auto grid content-section">
          <div class="apps-card">
            <div class="px-4 py-3 mb-8 app-card mt-4 w-100">
              <h2 class="my-6 text-2xl font-semibold">
                BlockChain Configuration -{" "}
                <Link to="#" onClick={connectingMetaMask}>
                  {connectMetaMask ? "Wallet Connected" : "Connect Wallet"}
                </Link>
              </h2>
              {connectMetaMask ? (
                <>
                  <h4>Wallet Address: {account}</h4>
                  <br />
                  <h4>
                    Balance: {window.web3.utils.fromWei(ethBalance, "Ether")}
                  </h4>
                </>
              ) : (
                ""
              )}
              <div class="grid gap-6 md:grid-cols-2 xl:grid-cols-2">
                <div className="custom-input">
                  <label class="block ">
                    <span class="">Contract Name</span>
                    <input
                      class="block form-input"
                      placeholder="Enter Contract Name"
                      type="text"
                      name="name"
                      onChange={(event) =>
                        setInputData({ ...inputData, name: event.target.value })
                      }
                    />
                  </label>
                </div>
                <div className="custom-input">
                  <label class="block">
                    <span class="">Symbol</span>
                    <input
                      class="block form-input"
                      placeholder="Enter Symbol"
                      type="text"
                      name="symbol"
                      onChange={(event) =>
                        setInputData({
                          ...inputData,
                          symbol: event.target.value,
                        })
                      }
                    />
                  </label>
                </div>
              </div>
              <div className="app-card-buttons sub-btn">
                <button
                  className="content-button status-button"
                  onClick={handleSubmit}
                  disabled={buttonContent != "" ? true : false}
                >
                  {buttonContent != "" ? buttonContent : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(DeployContractIndex);
